const assetUrl = 'https://assets.wearehectare.com'

export const avatars = {
  andrew: `${assetUrl}/avatars/andrew-hectare.jpg`,
  andrewNew: `${assetUrl}/avatars/andrew-1.svg`,
  user: `${assetUrl}/avatars/user-avatar.svg`
}

export const backgrounds = {
  advancePay: `${assetUrl}/backgrounds/advance-pay.png`,
  advancePayMobile: `${assetUrl}/backgrounds/advance-pay-mobile.png`,
  appBannerImage: `${assetUrl}/backgrounds/app-banner-image.png`,
  backgroundDark: `${assetUrl}/backgrounds/background-dark.png`,
  backgroundLight: `${assetUrl}/backgrounds/background-light.png`,
  createAccount: `${assetUrl}/backgrounds/create-account-background.jpg`,
  farming1: `${assetUrl}/backgrounds/farming-1.png`,
  farming2: `${assetUrl}/backgrounds/farming-2.png`,
  farming3: `${assetUrl}/backgrounds/farming-3.png`,
  farming4: `${assetUrl}/backgrounds/farming-4.png`,
  farming5: `${assetUrl}/backgrounds/farming-5.png`,
  farming6: `${assetUrl}/backgrounds/farming-6.png`,
  farming7: `${assetUrl}/backgrounds/farming-7.png`,
  farming8: `${assetUrl}/backgrounds/farming-8.png`,
  farming9: `${assetUrl}/backgrounds/farming-9.png`,
  graindex: `${assetUrl}/backgrounds/graindex.png`,
  harvestLineUp: `${assetUrl}/backgrounds/harvest-line-up.png`,
  helpCentreBannerDesktop: `${assetUrl}/backgrounds/help-centre-banner-image-desktop.jpg`,
  helpCentreBannerMobile: `${assetUrl}/backgrounds/help-centre-banner-image-mobile.jpg`,
  targetPrice: `${assetUrl}/backgrounds/target-price.png`,
  tradePositionPrompt: `${assetUrl}/backgrounds/trade-position-prompt.png`,
  welcomeTrading: `${assetUrl}/backgrounds/welcome-trading.jpg`,
  signupHectareTerms: `${assetUrl}/backgrounds/signup-hectare-terms.png`,
  signupCropPreferences: `${assetUrl}/backgrounds/signup-crop-preferences.png`,
  signupCommunications: `${assetUrl}/backgrounds/signup-communications.png`
}

export const icons = {
  advancePay: `${assetUrl}/icons/advance-pay.svg`,
  advancePayInverse: `${assetUrl}/icons/advance-pay-inverse.svg`,
  ahdb: `${assetUrl}/icons/ahdb.png`,
  appStoreBadge: `${assetUrl}/icons/app-store-badge.png`,
  farmtoFavicon: `${assetUrl}/icons/farmto-favicon.png`,
  fw: `${assetUrl}/icons/fw.png`,
  googlePlayBadge: `${assetUrl}/icons/google-play-badge.png`,
  graindex: `${assetUrl}/icons/graindex.svg`,
  hctr: `${assetUrl}/icons/hctr.png`,
  hectareFullRed: `${assetUrl}/icons/hectare-full-red.svg`,
  hectareFullWhite: `${assetUrl}/icons/hectare-full-white.svg`,
  hectareLogoSmall: `${assetUrl}/icons/hectare-logo-small.png`,
  hectareLogoWhite: `${assetUrl}/icons/hectare-logo-white.svg`,
  hectareLogo192x192: `${assetUrl}/icons/hectare-logo-192x912.png`,
  hectareLogo512x512: `${assetUrl}/icons/hectare-logo-512x512.png`,
  hectareVerified: `${assetUrl}/icons/hectare-verified.svg`,
  imageVideo: `${assetUrl}/icons/image-video.png`,
  mapBackground: `${assetUrl}/icons/map-background.jpg`,
  messageBubble: `${assetUrl}/icons/message-bubble.svg`,
  poweredByHectareVert: `${assetUrl}/icons/powered-by-hectare-vert.svg`,
  redTractorPng: `${assetUrl}/icons/red-tractor.png`,
  redTractorSvg: `${assetUrl}/icons/red-tractor.svg`,
  roads: `${assetUrl}/icons/roads.png`,
  satellite: `${assetUrl}/icons/satellite.png`,
  scottishAssurance: `${assetUrl}/icons/scottish-assurance.png`,
  wheat: `${assetUrl}/icons/wheat.svg`,
  coins: `${assetUrl}/icons/coins.svg`,
  farmLocations: `${assetUrl}/icons/farm-locations.png`
}

export const getStarted = {
  addAssuranceDetails: `${assetUrl}/get-started/add-assurance-details.jpg`,
  enhanceYourProfile: `${assetUrl}/get-started/enhance-your-profile.jpg`,
  postAListing: `${assetUrl}/get-started/post-a-listing.jpg`,
  setTargetPrices: `${assetUrl}/get-started/set-target-prices.jpg`,
  setupComplete: `${assetUrl}/get-started/setup-complete.jpg`
}
